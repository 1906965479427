import logo from './logo.svg';
import './App.css';
import MentionChart from "./MentionChart/MentionChart";
import MentionList from "./MentionList/MentionList";

function App() {
  return (
      <MentionChart></MentionChart>
  );
}

export default App;

